import { MapTo, Page, withComponentMappingContext } from '@adobe/aem-react-editable-components'
import { withRoute } from '../RouteHelper/RouteHelper'
import { PageMeta } from './PageMeta'

class AppPage extends Page {  
  /*
   * Overriding the @aem-react-editable-components method to pass in
   * 'aemNoDecoration={true}' property to suppress wrapper divs
   * aem-react-editable-components/src/components/Container.tsx
   */
  connectComponentWithItem(ChildComponent, itemProps, itemKey) {
    const itemPath = this.getItemPath(itemKey)

    return (
      <ChildComponent
        {...itemProps}
        key={itemPath}
        cqPath={itemPath}
        isInEditor={this.props?.isInEditor}
        aemNoDecoration={true}
        containerProps={this.getItemComponentProps(itemProps, itemKey, itemPath)}
      />
    )
  }

  render () {
    return (
      <>
        <PageMeta
          canonicalUrl={this.props?.canonicalUrl}
          cmpI18n={this.props?.cmpI18n}
          language={this.props?.language}
          robotsTags={this.props?.robotsTags}
          seoDescription={this.props?.description}
          seoImage={this.props?.metaImage}
          seoImageAlt={this.props?.metaImageAlt}
          seoTitle={this.props?.metaTitle}

        />
        {this.childComponents}
      </>
    )
  }
}

export default MapTo(['aramark/components/structure/page', 'aramark/components/structure/pages/section-landing', 'aramark/components/structure/pages/article-page'])(withComponentMappingContext(withRoute(AppPage)))
